import React from 'react';
import AOS from 'aos';
AOS.init();



const hero = () => {
	return(
		<div className="container">
			<div className="row">
				<div className="col-md-12 text-center">
	                <h2>My Resume</h2>
	                <div className="space-border"></div>
	            </div>
			</div>
			<div className="row gh-5">

				<div className="col-lg-6"
                    data-aos="fade-up"
                        data-aos-once="true"
                    >
                    <div className="p-4">
                        <h3 className="s_border">Training</h3>
                        <ul className="d_timeline">
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2003</h3>
                                <p className="d_timeline-text">
                                	<span className="d_title">Microsoft Gold Systems Engineer</span>
                                	<span className="d_company">Rumos</span>
                                	Planning, Implementing, Managing and Maintaining Microsoft Windows & Exchange.
                                </p>
                            </li>
                            
                                                        <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2003</h3>
                                <p className="d_timeline-text">
                                	<span className="d_title">MCSE - Core Infrastructure</span>
                                	<span className="d_company">Rumos</span>
                                	Cloud Platform & Infrastructure (Security).
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2004</h3>
                                <p className="d_timeline-text">
                                	<span className="d_title">CCNA - Implementing and Administering Cisco Solutions and HTI</span>
                                	<span className="d_company">Rumos</span>Routing and Switching, Hardening the Infrastructure.
                                </p>
                            </li>
                    
                    




                    
                        
                        <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2005 - 2008</h3>
                                <p className="d_timeline-text">
                                	<span className="d_title">Microsoft SQL Server</span>
                                	<span className="d_company">Rumos</span>Querying Microsoft SQL Server with Transact-SQL,
Implementing a Microsoft SQL Server Database, Developing
Applications Using ADO.NET for Microsoft SQL Server,
SQL Server Database Administration.
                                </p>
                            </li>



                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2018</h3>
                                <p className="d_timeline-text">
                                	<span className="d_title">Graphic Design</span>
                                	<span className="d_company">Flag</span>Adobe Photoshop, Adobe Illustrator, Adobe InDesign.
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2019</h3>
                                <p className="d_timeline-text">
                                	<span className="d_title">UX & UI and Web & Mobile Programming</span>
                                	<span className="d_company">Flag</span> Dreamweaver, Front-End Development with HTML5 and CSS3,
Responsive Web Design & Development, JavaScript Foundations, Responsive WordPress, MySQL Databases,
PHP (Hypertext Preprocessor).
                                </p>
                            </li>
                        </ul>
                    
                    
                    
                    
                    
                    
                    
                    
                    
                    
                    
                    
                    </div>
                </div>

                <div className="col-lg-6" 
                    data-aos="fade-up"
                        data-aos-once="true"
                    >
                    <div className="p-4">
                        

                        
                        <h3 className="s_border"><br/></h3>
                        

                        
                    </div>
                </div>
                <div className="spacer-double"></div>
			</div>
		</div>
	);
}

export default hero;