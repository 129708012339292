import React from 'react';
import AOS from 'aos';
AOS.init();

const whatido = () => {
    return(
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center"
                    data-aos="fade-up"
                    data-aos-delay="0"
                    data-aos-duration="1000"
                    data-aos-easing="ease"
                    data-aos-once="true"
                    >
                    <h2>What I Do</h2>
                    <div className="space-border"></div>
                </div>
            </div>
            <div className="spacer-single"></div>
            <div className="row">
                <div className="col-lg-4"
                    data-aos="fade"
                    data-aos-delay="300"
                    data-aos-duration="1000"
                    data-aos-easing="ease"
                    data-aos-once="true"
                    >
                    <div className="de_3d-box">
                        <div className="d-inner">
                            <i className="icon_desktop"></i>
                            <div className="text">
                                <h3>Systems Administrator</h3>
                                Managing and administering operating systems and respective communication networks is a critical role in ensuring the smooth functioning of computer systems and networks within an organization.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4" 
                    data-aos="fade"
                    data-aos-delay="400"
                    data-aos-duration="1000"
                    data-aos-easing="ease"
                    data-aos-once="true"
                    >
                    <div className="de_3d-box">
                        <div className="d-inner">
                            <i className="icon_tools"></i>
                            <div className="text">
                                <h3>
Software Development</h3>
Developing custom applications tailored to specific needs involves designing, creating, and deploying personalized software solutions for distinct users or organizations. This process ensures that the software aligns precisely with the unique requirements and expectations of the business, offering benefits such as increased productivity, full customization, scalability, integration capabilities, long-term return on investment, enhanced security, and efficient maintenance.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4"
                    data-aos="fade"
                    data-aos-delay="500"
                    data-aos-duration="1000"
                    data-aos-easing="ease"
                    data-aos-once="true"
                    >
                    <div className="de_3d-box">
                        <div className="d-inner">
                            <i className="icon_pencil"></i>
                            <div className="text">
                                <h3>Web Development, Web Design & Graphic Design

                                </h3>
                                The concepts of usability and user experience are essential in website design, ensuring that websites are easy to navigate, visually appealing, and provide a positive interaction for users. 
                                Digital pagination in Adobe InDesign is a process of creating and editing quality documents for printing and publication.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default whatido;